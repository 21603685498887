import type { LicencesCustomerAssignment } from "@/types/interfaces"
import { useLicenceStore } from "~/store/useLicenceStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const { establishment, links } = useAppConfig();
    const licenceStore = useLicenceStore();
    const { dateAddDays } = useUtilsFunctions();

    try {
        const { data } = await useFetch<LicencesCustomerAssignment>("/api/configs/licences");

        licenceStore.licence = data.value;
    } catch(err: any) {}

    if(licenceStore.licence) licenceStore.licenceEndDate = dateAddDays(licenceStore.licence.licenceLibrary.licenceValidityNbDays, licenceStore.licence.licenceLibrary.licenceStartDate)

    if(((to.path === links.devis) || (to.path === links.devisDraft) || (to.path === links.devisHistory)) && !licenceStore.isDevis(establishment.configuration.isDevis)) return await navigateTo(links.dashboard);
    if((to.path === links.maintenance) && !licenceStore.isVehicleMaintenance(establishment.configuration.isVehicleMaintenance)) return await navigateTo(links.catalog);
    if((to.path === links.manualRepairs) && !licenceStore.isManualRepair(establishment.configuration.isManualRepair)) return await navigateTo(links.catalog);
    if((to.path === links.shop) && !establishment.configuration.isShop) return await navigateTo(links.dashboard);
})