import { default as indexZyT4d6kYppMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom/index.vue?macro=true";
import { default as packagesdcBlTixkHoMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom/packages.vue?macro=true";
import { default as partsGFKHbM1xw9Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom/parts.vue?macro=true";
import { default as customQNfKVQ2RzAMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom.vue?macro=true";
import { default as indexJZvgoZ4ZvBMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/index.vue?macro=true";
import { default as rates2Iit3jYPx7Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/rates.vue?macro=true";
import { default as subscription6JJ8f0jpS9Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/subscription.vue?macro=true";
import { default as account8ZJwD5CWfrMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account.vue?macro=true";
import { default as _91id_93n6CZRwQIbVMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexvQNlkXIT3BMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93Xju1ygi7GVMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93gN0dCvY7M7Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as indexpJQP3ozcFAMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesoFelxn8um7Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93CCBXrq7mRNMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/Boutique/[title]-[id].vue?macro=true";
import { default as index7aJaNYxXkBMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/Boutique/index.vue?macro=true";
import { default as productshGPnwiEIgnMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/Boutique/products.vue?macro=true";
import { default as index74GplhnUb5Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/index.vue?macro=true";
import { default as configurationsiiwIsrFepMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexi1ECsOGMOyMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planKbI8kd0bH2Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksX06AQwKrWBMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenance2eBTvTeE9pMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manuals5YjVYc2wmcMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/manuals.vue?macro=true";
import { default as timesOXpwX3DyFKMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/times.vue?macro=true";
import { default as catalogCysMpFjwARMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog.vue?macro=true";
import { default as commandeMUskTZW4H8Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/commande.vue?macro=true";
import { default as _91tab_93eJSXXiZXeQMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93YPhbLkdfsCMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93wK8qkur3GsMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdf2q8obF5PM1Meta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexKdqXDANB3MMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/index.vue?macro=true";
import { default as loginhMVSJ8Bw2tMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/login.vue?macro=true";
import { default as panierPS411T696JMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/panier.vue?macro=true";
import { default as index1pg7hTly9wMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/pneumatique/index.vue?macro=true";
import { default as products1C7e7KkD1HMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiqueZbFxT3VCBWMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/pneumatique.vue?macro=true";
import { default as maintenanceJ7kRGg0MHSMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/print/maintenance.vue?macro=true";
import { default as productscRIBWW3ztiMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/products.vue?macro=true";
import { default as signupa9GDzrzG1dMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordnTU50uYv0mMeta } from "/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: account8ZJwD5CWfrMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: customQNfKVQ2RzAMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom.vue").then(m => m.default || m),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom/index.vue").then(m => m.default || m)
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom/packages.vue").then(m => m.default || m)
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/custom/parts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/rates.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/account/subscription.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/clients/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/clients/show/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/commandes/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/commandes/index.vue").then(m => m.default || m)
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/activite/vehicules.vue").then(m => m.default || m)
  },
  {
    name: "Boutique-title-id",
    path: "/Boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/Boutique/[title]-[id].vue").then(m => m.default || m)
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/Boutique/index.vue").then(m => m.default || m)
  },
  {
    name: "Boutique-products",
    path: "/Boutique/products",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/Boutique/products.vue").then(m => m.default || m)
  },
  {
    name: catalogCysMpFjwARMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: maintenance2eBTvTeE9pMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/configuration.vue").then(m => m.default || m)
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/plan.vue").then(m => m.default || m)
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/maintenance/works.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/manuals.vue").then(m => m.default || m)
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/catalog/times.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/commande.vue").then(m => m.default || m)
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/devis/[tab].vue").then(m => m.default || m)
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/devis/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/devis/show/[id].vue").then(m => m.default || m)
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdf2q8obF5PM1Meta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/imprimer-pdf.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhMVSJ8Bw2tMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/panier.vue").then(m => m.default || m)
  },
  {
    name: pneumatiqueZbFxT3VCBWMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/pneumatique.vue").then(m => m.default || m),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/pneumatique/index.vue").then(m => m.default || m)
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/pneumatique/products.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceJ7kRGg0MHSMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/print/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupa9GDzrzG1dMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app2.autopartspro.fr/pages/tableau-de-bord.vue").then(m => m.default || m)
  }
]