import {useColors} from "@/composables/useColors.js";

/**
 * Sets the application's color theme based on the user's preferred color scheme.
 * @param {Object} etablissementData - Data for the establishment. Contains the light and dark theme objects.
 * @returns {void}
 */
export const useThemeColor = (etablissementData) => {
	// const lightTheme = etablissementData.value.theme.light;
	// const darkTheme = etablissementData.value.theme.dark;

	// const isLightTheme = useMedia("(prefers-color-scheme: dark)");
	//
	// if (isLightTheme) {
	// 	themeToLoad = lightTheme;
	// } else {
	// 	themeToLoad = darkTheme;
	// }
	useColors(etablissementData);
};
