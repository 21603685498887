import { defineStore } from 'pinia';
import type { LicencesCustomerAssignment } from "@/types/interfaces"
export const useLicenceStore = defineStore({
  id: 'useLicenceStore',
  state: () => ({
    licence: <LicencesCustomerAssignment | null>{},
    licenceEndDate: <Date | null>null
  }),
  actions: {   
    isTechnicalData(establishmentIsTechnicalData: boolean | null) {
      if(establishmentIsTechnicalData !== null) return establishmentIsTechnicalData
      if(!this.licence) return false
      return this.licence.licenceLibrary.licencesType.licencesTypesConfiguration.isTechnicalData ?? false
    },
    isDevis(establishmentIsDevis: boolean | null) {
      if(establishmentIsDevis !== null) return establishmentIsDevis
      if(!this.licence) return false
      return this.licence.licenceLibrary.licencesType.licencesTypesConfiguration.isDevis ?? false
    },
    isVehicleMaintenance(establishmentIsVehicleMaintenance: boolean | null) {
      if(establishmentIsVehicleMaintenance !== null) return establishmentIsVehicleMaintenance
      if(!this.licence) return false
      return this.licence.licenceLibrary.licencesType.licencesTypesConfiguration.isVehicleMaintenance ?? false
    },
    isManualRepair(establishmentIsManualRepair: boolean | null) {
      if(establishmentIsManualRepair !== null) return establishmentIsManualRepair
      if(!this.licence) return false
      return this.licence.licenceLibrary.licencesType.licencesTypesConfiguration.isManualRepair ?? false
    },
    isTechnicalCharacteristicsParts(establishmentIsTechnicalCharacteristicsParts: boolean | null) {
      if(establishmentIsTechnicalCharacteristicsParts !== null) return establishmentIsTechnicalCharacteristicsParts
      if(!this.licence) return false
      return this.licence.licenceLibrary.licencesType.licencesTypesConfiguration.isTechnicalCharacteristicsParts ?? false
    },
    isGraphicResearch(establishmentIsGraphicResearch: boolean | null) {
      if(establishmentIsGraphicResearch !== null) return establishmentIsGraphicResearch
      if(!this.licence) return false
      return this.licence.licenceLibrary.licencesType.licencesTypesConfiguration.isGraphicResearch ?? false
    },
    isValidLicence() {
      return this.licenceEndDate ? this.licenceEndDate.getMilliseconds() > Date.now() : false;
    }
  }
});