<script setup lang="ts">
const { toasts, removeToast } = useAppToast()

const toastsList = computed(() => toasts.value)
</script>

<template>
    <div class="toastsCustom" v-if="toasts.length">
        <div v-for="toast in toastsList" :key="toast.id" class="toastCustom" >
            <div class="headerToast">
                <div>
                    <span v-if="toast.type === 'success'"><ElementsIcon class="iconSmall success" icon="fa-regular-circle-check"/> Succès</span>
                    <span v-if="toast.type === 'error'"><ElementsIcon class="iconSmall error" icon="fa-regular-circle-xmark"/> Erreur</span>
                    <span v-if="toast.type === 'info'"><ElementsIcon class="iconSmall info" icon="fa-regular-circle-info"/> Information</span>
                </div>
                <ElementsIcon class="close" icon="xmark" @click="removeToast(toast.id)"/>
            </div>
            <ElementsDivider class="m0 separator" :class="toast.type"/>
            <span class="text-sx">{{ toast.message }}</span>
            <div :style="{ animationDuration: toast.timeout + 'ms' }" :class="['progress', toast.type]"></div>
        </div>
    </div>
</template>
  
<style scoped lang="scss">
.toastsCustom {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1100001;
}

.headerToast {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div span {
        display: flex;
        align-items: center;
        gap: .3rem;
        & svg {
            &.success{
                color: var(--success-color);
            }
            &.error{
                color: var(--default-error-color);
            }
            &.info{
                color: var(--info-color);
            }
        }
    }
}

.headerToast .close {
    cursor: pointer;
    transition: all var(--animation-duration-site) ease;
    --icon-size-default: 15px;
    width: var(--icon-size-default);
    min-width: var(--icon-size-default);
    min-height: var(--icon-size-default);
    height: var(--icon-size-default);
}

.headerToast .close:hover,
.headerToast .close:focus {
  text-decoration: none;
  cursor: pointer;
  color: var(--mainColor);
}

.toastCustom {
    padding: .8rem;
    min-width: 250px;
    border-radius: .5rem;
    color: rgb(42, 42, 42);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: relative;
    overflow: hidden;
}

.progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    animation: shrink linear;
    &.success{
        background-color: var(--success-color);
    }
    &.error{
        background-color: var(--default-error-color);
    }
    &.info{
        background-color: var(--info-color);
    }
}

@keyframes shrink {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
</style>
  